import React, { Component } from 'react';

import '../index.scss';

class DOonatePage extends Component {
    render() {
        return (
            <div className='center'>
                <iframe src="https://fr.tipeee.com/absolumentouidev/cart?hostedIframe=1&amp;originUrl=https://fr.tipeee.com/absolumentouidev/hosted&amp;orientation=line&amp;rewards=1" scrolling="no" height="100%" frameBorder="0" style={{width: "1px", minWidth: "100%", overflow: "hidden", border: "1px solid rgb(232, 232, 232)", borderRadius: "20px", height: "715px"}}></iframe>
            </div>
        );
    }

    componentDidMount() {
        
    }
}

export default DOonatePage;