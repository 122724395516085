import React, { Component } from 'react';

import '../index.scss';

class UsersPage extends Component {
    render() {
        return (
            <div className='center'>
                <h1>Users</h1>
            </div>
        );
    }

    componentDidMount() {
        
    }
}

export default UsersPage;